import React, { useCallback, useEffect, useRef } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import ArrowRight from '~/images/icons/slideshow_arrow_right.svg';
import ArrowLeft from '~/images/icons/slideshow_arrow_left.svg';
import { WhiteButton } from '~/components/.base/buttons';

const SlideshowCont = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  .carousel {
    .carousel-status {
      display: none;
    }
    .slide {
      background-color: transparent;
    }
    .prev-next {
      position: absolute;
      bottom: 30px;
      top: ${props => props.arrowTop};
      bottom: ${props => props.arrowBottom};
      width: 30px;
      height: 30px;
      min-height: 30px;
      padding: 0px;
      z-index: 1;
      overflow: visible;
      svg {
        width: 16px;
        stroke: #d4212c;
        position: absolute;
      }
      #extended {
        display: none;
      }
      @media screen and (min-width: 768px) {
        &:hover,
        &:focus {
          svg {
            stroke: #fff;
          }
        }
      }
    }
    .prev {
      left: ${({ prevArrowLeft }) => (prevArrowLeft ? prevArrowLeft : '20px')};
      right: ${props => props.prevArrowRight};
      &.extended {
        svg {
          overflow: visible;
          fill: #d4212c;
          transform: rotate(180deg);
          g,
          path {
            position: absolute;
            right: 0;
          }
        }
        #hide {
          display: none;
        }
      }
    }
    .next {
      left: ${({ nextArrowLeft }) => (nextArrowLeft ? nextArrowLeft : '60px')};
      right: ${props => props.nextArrowRight};
      .homepageHeroExtended {
        display: none;
      }
      &.extended {
        svg {
          overflow: visible;
          fill: #d4212c;
          g,
          path {
            position: absolute;
            right: 0;
          }
        }
        #hide {
          display: none;
        }
      }
    }
    .control-dots {
      left: 130px;
      bottom: 30px;
      height: 30px;
      margin: 0px;
      top: ${props => props.dotTop};
      left: ${props => props.dotLeft};
      right: ${props => props.dotRight};
      bottom: ${props => props.dotBottom};
      width: ${props => props.dotWidth};
      .circle {
        display: inline-block;
        outline: none;
        border-radius: 2px;
        width: 30px;
        height: 30px;
        padding: 13.5px 0;
        background: transparent;
        margin-right: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        .inner {
          background-color: #fff;
          width: 100%;
          height: 100%;
          vertical-align: top;
        }
        &.selected {
          width: 90px;
          .inner {
            width: 100%;
            height: 100%;
            background-color: #d4212c;
          }
        }
      }
    }
  }
`;

const Slideshow = ({
  handleChange,
  children,
  arrowPosition,
  dotPosition,
  currentSlide,
  noLoop,
  autoPlay,
  interval,
  carouselLabel,
}) => {
  if (!arrowPosition) arrowPosition = { prev: {}, next: {} };
  if (!dotPosition) dotPosition = {};
  const slideshowRef = useRef(null);
  const handleInteractiveElementsFocusable = useCallback(() => {
    if (slideshowRef?.current) {
      const slides = slideshowRef.current.querySelectorAll('.slide');
      const selectedSlide = slideshowRef.current.querySelector('.slide.selected');
      slides.forEach(function (element) {
        element.setAttribute('aria-hidden', true);
      });
      selectedSlide.removeAttribute('aria-hidden');

      const allInteractiveElements =
        slideshowRef.current.querySelectorAll('.slide a, .slide button');

      // Make sure that no interactive elements of the inactive slide is focusable
      allInteractiveElements.forEach(function (element) {
        element.setAttribute('tabindex', -1);
      });

      // Make sure all interactive elements of the active slide are focusable
      const activeSlideInteractiveElements = selectedSlide.querySelectorAll(`a, button`);

      activeSlideInteractiveElements.forEach(function (element) {
        element.removeAttribute('tabindex');
      });
    }
  }, [slideshowRef]);
  useEffect(() => {
    handleInteractiveElementsFocusable();
  }, [handleInteractiveElementsFocusable]);
  return (
    <SlideshowCont
      ref={slideshowRef}
      className="slideshow"
      aria-label={`${carouselLabel || ''} carousel`}
      arrowTop={arrowPosition.top}
      arrowBottom={arrowPosition.bottom}
      arrowLeft={arrowPosition.left}
      arrowRight={arrowPosition.right}
      prevArrowLeft={arrowPosition.prev.left}
      prevArrowRight={arrowPosition.prev.right}
      nextArrowLeft={arrowPosition.next.left}
      nextArrowRight={arrowPosition.next.right}
      dotTop={dotPosition.top}
      dotBottom={dotPosition.bottom}
      dotLeft={dotPosition.left}
      dotRight={dotPosition.right}
      dotWidth={dotPosition.width}
    >
      <p class="sr-only">
        This is a carousel. Use Next and Previous buttons to navigate, or jump to a slide with the
        slide dots.
      </p>
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={25}
        infiniteLoop={!noLoop}
        autoPlay={autoPlay}
        interval={interval}
        selectedItem={currentSlide}
        renderThumbs={() => null}
        onChange={(currentIndex, item) => {
          if (handleChange) {
            handleChange(currentIndex, item);
          }
          handleInteractiveElementsFocusable();
        }}
        renderArrowPrev={clickHandler => {
          return (
            <WhiteButton onClick={clickHandler} className="prev-next prev">
              <ArrowLeft />
              <span class="sr-only">Previous slide</span>
            </WhiteButton>
          );
        }}
        renderArrowNext={clickHandler => {
          return (
            <WhiteButton onClick={clickHandler} className="prev-next next" aria-label="next slide">
              <ArrowRight />
              <span class="sr-only">Next slide</span>
            </WhiteButton>
          );
        }}
        renderIndicator={(clickHandler, isSelected, index, label) => (
          <li className={`circle ${isSelected ? 'selected' : ''}`}>
            <button
              className="inner"
              value={index}
              tabIndex="0"
              aria-label={`Go to slide ${index + 1}`}
              aria-current={isSelected ? 'true' : 'false'}
              onClick={clickHandler}
            >
              <span class="sr-only">Go to slide ${index + 1}</span>
            </button>
          </li>
        )}
      >
        {children}
      </Carousel>
    </SlideshowCont>
  );
};

export default Slideshow;
